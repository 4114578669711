.Microphone {
  display: flex;

  flex-direction: column;
  flex-wrap: nowrap;

  text-align: center;

  box-sizing: border-box;

  padding: 1em;
}

.Microphone__label {
  flex-shrink: 0;
}

.Microphone__button {
  appearance: none;
  background: none;
  border: none;
  display: block;
  height: 64px;
  margin: 0 auto 1em auto;
  outline: none;
  padding: 0;
  width: 64px;
  flex-shrink: 0;
}

.Microphone__svg {
  border-radius: 50%;
}

.Microphone__svg_record {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(119, 179, 212, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(119, 179, 212, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(119, 179, 212, 0);
  }
}