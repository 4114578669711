.Track {
  text-align: center;
  height: 250px;
  box-sizing: border-box;
  padding: 80px 0;
  margin: 0;
}

.Track__title {
  margin-bottom: 1em;
}
.Visualizer {
  display: block;

  width: 320px;
  height: 250px;

  margin: 0 auto;

  padding: 0;
}

.Visualizer path {
  stroke: white;
  stroke-linecap: square;
  stroke-width: .5px;
}

.Microphone {
  display: flex;

  flex-direction: column;
  flex-wrap: nowrap;

  text-align: center;

  box-sizing: border-box;

  padding: 1em;
}

.Microphone__label {
  flex-shrink: 0;
}

.Microphone__button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background: none;
  border: none;
  display: block;
  height: 64px;
  margin: 0 auto 1em auto;
  outline: none;
  padding: 0;
  width: 64px;
  flex-shrink: 0;
}

.Microphone__svg {
  border-radius: 50%;
}

.Microphone__svg_record {
  -webkit-animation: pulse 1s infinite;
          animation: pulse 1s infinite;
}

@-webkit-keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(119, 179, 212, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(119, 179, 212, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(119, 179, 212, 0);
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(119, 179, 212, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(119, 179, 212, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(119, 179, 212, 0);
  }
}
.App__plug {
  height: 250px;
}
