.Track {
  text-align: center;
  height: 250px;
  box-sizing: border-box;
  padding: 80px 0;
  margin: 0;
}

.Track__title {
  margin-bottom: 1em;
}