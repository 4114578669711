.Visualizer {
  display: block;

  width: 320px;
  height: 250px;

  margin: 0 auto;

  padding: 0;
}

.Visualizer path {
  stroke: white;
  stroke-linecap: square;
  stroke-width: .5px;
}
